
import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../hooks/auth';
import { Container, Table } from './styles';
import RowSimpleGrouped from '../RowSimpleGrouped/index';
import ColumnSimpleGrouped from '../ColumnSimpleGrouped';
import Swal from 'sweetalert2';
import Api, { exceptionNotificationAPI } from '../../services/api';
import { AiOutlineDelete, AiFillCloseCircle, AiFillFileWord, AiFillFilePdf, AiFillFileExcel, AiFillFileImage } from 'react-icons/ai'
import { FaAngleDown, FaAngleUp, FaFileAlt } from 'react-icons/fa';
import Collapse from 'react-bootstrap/Collapse';
import TooltipComponent from '../TooltipComponent';
import { CommentTextArea } from './styles';
import ChatButton from '../ChatButton/index';
import useScrollOnDrag from 'react-scroll-ondrag';
import EvidenceButton from '../EvidenceButton';
import GetTypeInput from '../FormInput/set';
import { removeFile, getAnswers } from '../../services/functions';


const GroupedByColumnRowTable = ({
    calculations, subSection, subSectionGroupColumnInfo, moduleObject, setModuleObject, toleranceValidations,
    formatValuesInTooltip, setNumber, setText, funcCollapse, collapse, collapseText, collapseRow, collapseCol, defaultChatRequest,
    setComment, uploadFile, getFile, blockedField, totalColumns, kpis, replaceScore, hasActionPlanModule,
    MODULE_ID, setChatRequest, setShowChat, chatRequest, kpiDiscussions, kpiEvidences, setKPIEvidences, defaultValues,
    subSectionKPIsToBlock, setSubSectionKPIsToBlock, loading, subSectionKPIs, changeSelectedTobaccoType, changeSelectedCountry, changeSelectedFuelType, selectedTobaccoTypes, selectedFuelTypes, selectedCountries, selectedCuringBarns, tobaccoTypeID = null,
    updateSelectedFuelType, updateSelectedCountry, loadRefNumbersAndCalculations, rejectedKpisList, fixedKpisList, changeSelectedCuringBarn, updateSelectedCuringBarn, setEvidenceRequest, setShowUploadEvidence, evidenceRequest, defaultEvidenceRequest, waiverIdentifier = null
}) => {
    const ref = useRef();
    const { events } = useScrollOnDrag(ref);
    const { userRoles } = useAuth();
    const [numberOfRows, setNumberOfRows] = useState();
    const [descriptionColumnsWidth, setDescriptionColumnsWidth] = useState();
    const [valueColumnsWidth, setValueColumnsWidth] = useState();
    const [selectedSSK, setSelectedSSK] = useState();
    const [showDescriptionColumn, setShowDescriptionColumn] = useState(true);
    const [showUnitColumn, setShowUnitColumn] = useState(true);
    const [showSecondaryUnitsColumn, setShowSecondaryUnitsColumn] = useState(false);
    const [isRowOrColumn, setIsRowOrColumn] = useState(null);
    const [kpiColumns, setkpiColumns] = useState([]);
    const [isColumnOverload, setisColumnOverload] = useState(subSection.columnRows.length > 7 || (subSection.columnRows.length * subSection.columnRows.find(x => x.minimumQuantity > 0)?.minimumQuantity > 7));

    //Column Types
    const COLUMN_ROW_TYPES = {
        'Simple': 1,
        'Country': 2,
        'FuelType': 3,
        'TobaccoType': 4,
        'Year': 5,
    };

    var List = [];

    useEffect(() => {
        var totalColumns = 0;
        subSection.columnRows.map(e => {
            if (e.minimumQuantity) {
                totalColumns += e.minimumQuantity;
            } else {
                totalColumns += 1
            }
        });


        var columnsCount = subSection.columnRows.length;
        if (subSection.isColumnRow == false) {

            if (columnsCount == 1 && subSection.columnRows[0].minimumQuantity > 0) {
                setDescriptionColumnsWidth(10);
                setValueColumnsWidth(20);
            }
            else if (columnsCount == 2) {
                setDescriptionColumnsWidth(15);
                setValueColumnsWidth(20);
            }
            else if (columnsCount > 2 && columnsCount <= 5) {
                setDescriptionColumnsWidth(40);
                setValueColumnsWidth(10);
            }
            else if (columnsCount > 5 && columnsCount <= 12) {
                setDescriptionColumnsWidth(10);
                setValueColumnsWidth(20);
            }
            else {
                setDescriptionColumnsWidth(20);
                setValueColumnsWidth(20);
            }
        }
        else {
            var columnsize = Math.round((96 / columnsCount) * 100) / 100;
            setDescriptionColumnsWidth(0);
            setValueColumnsWidth(columnsize);

            if (columnsCount == 2) {
                setDescriptionColumnsWidth(30);
                setValueColumnsWidth(20);
            }
            // else if (columnsCount > 2 && columnsCount <= 5) {
            //     setDescriptionColumnsWidth(0);
            //     setValueColumnsWidth(20);
            // }
            // else if (columnsCount > 5) {
            //     setDescriptionColumnsWidth(0);
            //     setValueColumnsWidth(15);
            // }
            // else {
            //     setDescriptionColumnsWidth(0);
            //     setValueColumnsWidth(10);
            // }
        }
        setIsRowOrColumn(subSection.isColumnRow);
        setNumberOfRows(subSection.numberOfRows < 0 ? 0 : subSection.numberOfRows);
    }, [])

    useEffect(() => {
        if (subSection.isActionPlan) {
            setIsRowOrColumn(subSection.isColumnRow);
            setNumberOfRows(subSection.numberOfRows < 0 ? 0 : subSection.numberOfRows);
        }
    }, [moduleObject])

    const reordenateColumns = (columns) => {
        var columnsSorted = columns.sort((a, b) => a.columnRowOrder > b.columnRowOrder ? 1 : -1);
        return columnsSorted;
    }




    const addNewLine = (ss, boolSet = false, value = null) => {
        if (ss.isActionPlan) {
            var columnRowKey = ss.columnRows.find(e => e.name.includes("Key") || e.name.includes("Section"))
            if (columnRowKey) {
                var sskpi = subSectionKPIs.find(e => e.columnRowID == columnRowKey.columnRowID);
                var valueToCompare = !boolSet ? subSection.description : value
                var newAnswers = moduleObject.answers;
                var myAnswer = getAnswers(
                    {
                        'newAnswers': newAnswers,
                        'ref': sskpi.refNumber,
                        'subSectionKPI': sskpi,
                        'row': ss.numberOfRows - 1
                    })[0];

                var existedAnswers = getAnswers(
                    {
                        'newAnswers': newAnswers,
                        'ref': sskpi.refNumber,
                        'subSectionKPI': sskpi,
                        'row': ss.numberOfRows - 1
                    })[0];

                var existAnswer = null

                for (var i = 0; i <= ss.numberOfRows; i++) {
                    var existedAnswers = getAnswers(
                        {
                            'newAnswers': newAnswers,
                            'ref': sskpi.refNumber,
                            'subSectionKPI': sskpi,
                            'row': i
                        })[0];

                    var existAnswer = null
                    if (existedAnswers) {
                        if (typeof existedAnswers === 'object') {
                            if (existedAnswers.textValue == valueToCompare) {
                                existAnswer = existedAnswers
                            }
                        }
                        if (existAnswer)
                            return moduleObject;
                    }
                }

                var newNumber = ss.numberOfRows

                if (myAnswer || ss.numberOfRows == 0) {
                    newNumber = newNumber + 1;
                }

                moduleObject.data.sections.map((section) => {
                    var currentSubSection = section.subSections.find(x => x.subSectionID == ss.subSectionID);
                    if (currentSubSection != null) {
                        currentSubSection.numberOfRows = newNumber;
                    }
                });

                var newModuleObject = setText({
                    'subSectionKPI': sskpi,
                    'ref': sskpi.refNumber,
                    'value': valueToCompare,
                    'row': newNumber - 1,
                    'moduleObject': moduleObject,
                    'setModuleObject': setModuleObject,
                    'subSectionKPIs': subSectionKPIs,
                    'isActionPlan': boolSet ? false : true
                })
                return newModuleObject
            }

        } else {
            var newNumber = numberOfRows + 1;
            moduleObject.data.sections.map((section) => {
                var currentSubSection = section.subSections.find(x => x.subSectionID == ss.subSectionID);
                if (currentSubSection != null) {
                    currentSubSection.numberOfRows = newNumber;
                }
            });
            setNumberOfRows(newNumber);
            setModuleObject({ ...moduleObject })
        }
    }

    const removeLastLine = (ss, row) => {
        var newList = [];
        newList = moduleObject.answers.filter(x => x.subSectionID != ss.subSectionID || (x.subSectionID == ss.subSectionID && x.row != row));
        Swal.fire({
            title: 'Are you sure you want to remove the line',
            text: "If you press Yes, the line will be removed and also the data.",
            icon: 'warning',
            showDenyButton: true,
            confirmButtonColor: '#112A61',
            denyButtonColor: '#B0B0BB',
            denyButtonText: 'No',
            confirmButtonText: 'Yes',
            showCloseButton: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                var newNumber = numberOfRows - 1;

                var sskList = moduleObject.answers.filter(x => x.subSectionID == ss.subSectionID && x.row == row);
                if (sskList != null && sskList.length > 0) {
                    var targetSSK = subSectionKPIs.find(x => x.subSectionKPIID == sskList[0].subSectionKPIID);
                    var targetColumn = moduleObject.columnRows.find(x => x.columnRowID == targetSSK.columnRowID);
                    var ssk = subSectionKPIs.find(x => x.refNumber == targetColumn.kpiReference);
                    //Adicionar validador se coluna refere-se a Fuel OU Country, e chamar update referente
                    if (ssk != null && ssk != '') {

                        if (COLUMN_ROW_TYPES.Country == targetColumn.type) {
                            updateSelectedCountry('', ssk, row, true);//need fixing
                        }
                        if (COLUMN_ROW_TYPES.FuelType == targetColumn.type) {
                            updateSelectedFuelType('', ssk, row, true);//need fixing
                            updateSelectedCuringBarn('', ssk, row, true);//need fixing
                        }
                    }
                    else if (COLUMN_ROW_TYPES.Simple == targetColumn.type) {
                        moduleObject.data.sections.map((section) => {
                            var currentSubSection = section.subSections.find(x => x.subSectionID == ss.subSectionID);
                            if (currentSubSection != null) {
                                currentSubSection.numberOfRows = newNumber;
                            }
                        });

                        moduleObject.removedAnswers = sskList;

                        setModuleObject({ ...moduleObject, answers: newList });
                    }

                    newList.map((item) => {
                        if (item.row > row && item.subSectionID == ss.subSectionID) {
                            return item.row = item.row - 1;
                        }
                    });

                }
                setNumberOfRows(newNumber);
            }
        });
    }

    const removeActionPlanLine = (ss, sskpi, isOne = false) => {
        var sskList = subSectionKPIs.filter(e => e.subSectionID == subSection.subSectionID && e.isActionPlan)
        if (sskList && sskList.length > 0) {
            var newAnswers = moduleObject.answers;
            var removeLine = true
            var countUndefineds = 0
            if (isOne) {
                var hasAnswer = getAnswers(
                    {
                        'newAnswers': newAnswers,
                        'ref': sskpi.refNumber,
                        'subSectionKPI': sskpi
                    })[0];
                if (!hasAnswer) {
                    removeLine = false
                }
            } else {
                sskList.forEach(e => {
                    var hasAnswer = getAnswers(
                        {
                            'newAnswers': newAnswers,
                            'ref': e.refNumber,
                            'subSectionKPI': e
                        })[0];
                    if (sskpi.refNumber != e.refNumber) {
                        if (hasAnswer && (hasAnswer.textValue === "Not Compliant" || (hasAnswer.numberValue != null && hasAnswer.numberValue < 3) || hasAnswer.textValue === "Non-Compliant")) {
                            removeLine = false
                        } else if (!hasAnswer) {
                            countUndefineds++
                        }
                    }
                })
            }
            /*if (countUndefineds + 1 == sskList.length) {
                removeLine = false
            }*/

            if (removeLine) {
                var newList = [];

                var row = moduleObject.answers.filter(e => e.textValue == subSection.description)[0]?.row
                newList = moduleObject.answers.filter(x => x.subSectionID != ss.subSectionID || (x.subSectionID == ss.subSectionID && x.row != row));
                if (row != null) {
                    var newNumber = ss.numberOfRows - 1;

                    var sskList = moduleObject.answers.filter(x => x.subSectionID == ss.subSectionID && x.row == row);


                    moduleObject.data.sections.map((section) => {
                        var currentSubSection = section.subSections.find(x => x.subSectionID == ss.subSectionID);
                        if (currentSubSection != null) {
                            currentSubSection.numberOfRows = newNumber;
                        }
                    });

                    moduleObject.removedAnswers = sskList;

                    var newModuleObject = { ...moduleObject, answers: newList }

                    newList.map((item) => {
                        if (item.row > row && item.subSectionID == ss.subSectionID) {
                            return item.row = item.row - 1;
                        }
                    });

                    newModuleObject.data.sections.map((section) => {
                        var currentSubSection = section.subSections.find(x => x.subSectionID == ss.subSectionID);
                        if (currentSubSection != null) {
                            currentSubSection.numberOfRows = newNumber;
                        }
                    });


                    return newModuleObject;
                }
            }
        }
    }


    return (

        subSection && isRowOrColumn != null &&

        <Container className={subSection.columnRows.length > 7 || (subSection.columnRows.length * subSection.columnRows.find(x => x.minimumQuantity > 0)?.minimumQuantity > 7) ? "scroll-div" : ""} {...events} ref={ref}>
            <div className='div-header'>
                <tr>
                    <div className="sub-section-description" colSpan={subSection.columnRows.length > 7 ? subSection.columnRows.length : 7}>{subSection.description}</div>
                </tr>
                {
                    subSection.guidance != "" &&
                    <tr>
                        <div className="sub-section-guidance" colSpan={subSection.columnRows.length > 7 ? subSection.columnRows.length : 7}>{subSection.guidance}</div>
                    </tr>
                }
            </div>

            <div style={isColumnOverload ? { height: (subSection.kpis?.length) > 4 ? "600px" : {} } : {}} className={isColumnOverload ? 'tableFixHead' : ''}>
                <Table className="w-100">
                    <thead className='primary-header sticky-top'>
                        <>
                            {!isRowOrColumn ?
                                <>
                                    {
                                        subSection && <>
                                            <>
                                                {
                                                    subSection.columnRows && <tr>
                                                        <th rowSpan={2} width="2%" className={isColumnOverload ? 'fixed-head' : {}} style={{ border: '2px solid #dadada' }}></th>
                                                        <th rowSpan={2} width={`${descriptionColumnsWidth}%`} className={isColumnOverload ? 'fixed-head' : {}} style={isColumnOverload ? { minWidth: '200px', paddingLeft: '20px', left: '4%', border: '2px solid #dadada' } : { border: '2px solid #dadada' }}>Description</th>
                                                        <th rowSpan={2} width="5%" className="text-center" style={{ border: '2px solid #dadada' }}>Units</th>
                                                        <>
                                                            {
                                                                reordenateColumns(subSection.columnRows).filter(x => x.isParentColumn == false).map((childColumn, index) => {
                                                                    if (childColumn.parentColumnRowID == null) {
                                                                        if (childColumn.minimumQuantity > 1) {

                                                                            List.push({
                                                                                parentName: "",
                                                                                columnRowOrder: 1,
                                                                                minimumQuantity: childColumn.minimumQuantity,
                                                                                parentColumnRowID: childColumn.parentColumnRowID,
                                                                                colSpan: childColumn.minimumQuantity,
                                                                                type: childColumn.type,
                                                                                index: index
                                                                            })
                                                                        }
                                                                        else {
                                                                            List.push({
                                                                                parentName: "",
                                                                                columnRowOrder: index,
                                                                                parentColumnRowID: childColumn.parentColumnRowID,
                                                                                colSpan: 1,
                                                                                index: index
                                                                            })
                                                                        }

                                                                    }
                                                                    else {
                                                                        var parentExists = List.find(x => x.parentColumnRowID == childColumn.parentColumnRowID)
                                                                        var parentCol = subSection.columnRows.find(x => x.columnRowOrder == childColumn.parentColumnRowID)
                                                                        if (!parentExists && parentCol) {
                                                                            List.push({
                                                                                parentName: parentCol.name,
                                                                                minimumQuantity: parentCol.minimumQuantity,
                                                                                parentColumnRowID: childColumn.parentColumnRowID,
                                                                                columnRowOrder: index,
                                                                                colSpan: 1,
                                                                                type: parentCol.type,
                                                                                index: index
                                                                            })

                                                                        }
                                                                        else {
                                                                            var parentList = List.filter(x => x.parentColumnRowID != childColumn.parentColumnRowID);
                                                                            parentExists.colSpan = parseInt(parentExists.colSpan) + 1
                                                                            parentList.push(parentExists)
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                            {
                                                                List.some(x => x.parentName != "") &&
                                                                reordenateColumns(List).map((groupByColumnObject, index) => {
                                                                    if (groupByColumnObject.minimumQuantity != null && groupByColumnObject.minimumQuantity > 1) {
                                                                        return [...Array(groupByColumnObject.minimumQuantity)].map((x, rowI) => {
                                                                            return (<th style={{ border: '2px solid #dadada', padding: '1rem 0rem 1rem 0' }} className="text-center aaa" colSpan={groupByColumnObject.colSpan} key={`groupByColumn-${index}`} >{groupByColumnObject.type == 4 ? selectedTobaccoTypes[rowI]?.text : groupByColumnObject.parentName + " " + parseInt(rowI + 1)}</th>)
                                                                        })
                                                                    }
                                                                    else {
                                                                        return <>
                                                                            <th id={`columnRowID_${groupByColumnObject.columnRowID}`}
                                                                                style={{ border: '2px solid #dadada', padding: '1rem 0rem 1rem 0rem' }}
                                                                                colSpan={groupByColumnObject.colSpan}
                                                                                className={"text-center kkk"}
                                                                                key={`titleOfColumn-${index}`}
                                                                                width={"10%"}>
                                                                                {groupByColumnObject.parentName}
                                                                            </th>
                                                                        </>
                                                                    }

                                                                }
                                                                )
                                                            }
                                                            {/* {
                                                                subSection.columnRows.filter(x => x.isParentColumn == true).length > 0 && subSection.columnRows.filter(x => x.isParentColumn == false && x.parentColumnRowID == null).map((groupByColumnObject, index) => {
                                                                    return (<td style={{ border: '1px solid #ECECF3', padding: '1rem' }} className="text-center" colSpan={1} key={`groupByColumn-${index}`} width={`${valueColumnsWidth}%`}>{ }</td>)
                                                                })
                                                            } */}
                                                        </>
                                                    </tr>
                                                }
                                            </>
                                            <tr>

                                                <>
                                                    {
                                                        reordenateColumns(subSection.columnRows).filter(x => x.isParentColumn == true)[0]?.minimumQuantity > 0 ?
                                                            [...Array(reordenateColumns(subSection.columnRows).filter(x => x.isParentColumn == false)[0].minimumQuantity)].map((x, rowI) => {
                                                                return reordenateColumns(subSection.columnRows).filter(x => x.isParentColumn == false).map((columnRow, index) => {
                                                                    return <th id={`ColumnRow_${columnRow.columnRowID}_${index}`} style={{ border: '2px solid #dadada', padding: '1rem 0rem 1rem 0rem' }} className="text-center" width="10%">{columnRow.name}</th>
                                                                })
                                                            })
                                                            :
                                                            reordenateColumns(subSection.columnRows).filter(x => x.isParentColumn == false).map((columnRow, index) => {
                                                                if (columnRow.minimumQuantity != null && columnRow.minimumQuantity > 1) {
                                                                    return [...Array(columnRow.minimumQuantity)].map((x, rowI) => {
                                                                        return <th id={`ColumnRow_${columnRow.columnRowID}_${index}`} style={{ border: '2px solid #dadada', padding: '1rem 0rem 1rem 0rem' }} className="text-center" width="10%">{columnRow.type == 4 ? selectedTobaccoTypes[rowI]?.text : columnRow.name + " " + parseInt(rowI + 1)}</th>
                                                                    })
                                                                }
                                                                else {
                                                                    return <>
                                                                        <th id={`columnRowID_${columnRow.columnRowID}`} style={{ border: '2px solid #dadada', padding: '1rem 0rem 1rem 0rem' }} className={"text-center"} key={`titleOfColumn-${index}`} width={"10%"}>{columnRow.name}</th>
                                                                    </>
                                                                }
                                                            })
                                                    }
                                                </>
                                            </tr>
                                        </>
                                    }
                                </>
                                :
                                <>
                                    {subSection && <>
                                        {
                                            reordenateColumns(subSection.columnRows).filter(x => x.isParentColumn == false).map((childColumn, index) => {
                                                if (childColumn.parentColumnRowID == null) {
                                                    if (childColumn.minimumQuantity > 1) {
                                                        List.push({
                                                            parentName: "",
                                                            columnRowOrder: 1,
                                                            parentColumnRowID: childColumn.parentColumnRowID,
                                                            colSpan: childColumn.minimumQuantity,
                                                            index: index
                                                        })
                                                    }
                                                    else {
                                                        List.push({
                                                            parentName: "",
                                                            columnRowOrder: index,
                                                            parentColumnRowID: childColumn.parentColumnRowID,
                                                            colSpan: 1,
                                                            index: index
                                                        })
                                                    }
                                                }
                                                else {
                                                    var parentExists = List.find(x => x.parentColumnRowID == childColumn.parentColumnRowID)
                                                    var parentCol = subSection.columnRows.find(x => x.columnRowOrder == childColumn.parentColumnRowID)
                                                    if (!parentExists && parentCol) {
                                                        List.push({
                                                            parentName: parentCol.name,
                                                            parentColumnRowID: childColumn.parentColumnRowID,
                                                            columnRowOrder: index,
                                                            colSpan: 1,
                                                            index: index
                                                        })
                                                    }
                                                    else {
                                                        var parentList = List.filter(x => x.parentColumnRowID != childColumn.parentColumnRowID);
                                                        parentExists.colSpan = parseInt(parentExists.colSpan) + 1
                                                        parentList.push(parentExists)
                                                    }
                                                }
                                            })
                                        }
                                        {List.some(x => x.parentName != "") &&
                                            <tr>
                                                <th rowSpan={1} width="2%"></th>
                                                {
                                                    reordenateColumns(List).map((groupByColumnObject, index) => {
                                                        var colSpanToParent = subSection.columnRows.filter(x => x.parentColumnRowID == groupByColumnObject.columnRowOrder).length;
                                                        const hasBorder = groupByColumnObject.parentName !== "";

                                                        return (
                                                            <th
                                                                style={{
                                                                    border: hasBorder ? '2px solid #dadada' : 'none',
                                                                    padding: '1rem'
                                                                }} className="text-center ss" colSpan={groupByColumnObject.colSpan} key={`groupByColumn-${index}`} >{groupByColumnObject.parentName}</th>)
                                                    })
                                                }
                                                {/* {
                                                    subSection.columnRows.filter(x => x.isParentColumn == true).length > 0 && subSection.columnRows.filter(x => x.isParentColumn == false && x.parentColumnRowID == null).map((groupByColumnObject, index) => {
                                                        return (<td style={{ border: '1px solid #ECECF3', padding: '1rem' }} className="text-center" colSpan={1} key={`groupByColumn-${index}`} width={`${valueColumnsWidth}%`}>{ }</td>)
                                                    })
                                                } */}
                                                {moduleObject.isEditable == true && (subSection.numberOfRows > 0) &&
                                                    <th key={`KPIColumnRow_del`} width={"2%"}></th>
                                                }
                                            </tr>
                                        }
                                        <tr>
                                            {subSection.columnRows.filter(x => x.isParentColumn == false && x.type != 2).length > 0 &&
                                                <th rowSpan={2} width="2%" style={{ border: '2px solid #dadada' }}></th>
                                            }
                                            <>
                                                {
                                                    reordenateColumns(subSection.columnRows).filter(x => x.isParentColumn == false).map((columnRow, index) => {
                                                        return <>
                                                            <th style={{ fontSize: '12px', border: '2px solid #dadada' }} className="text-center" width={valueColumnsWidth + "%"} key={`groupByColumn-${index}`}>{columnRow.name}</th>
                                                        </>
                                                    })
                                                }
                                                {moduleObject.isEditable == true && (subSection.numberOfRows > 0) &&
                                                    <th key={`KPIColumnRow_del`} width={"1%"}>
                                                    </th>
                                                }
                                            </>
                                        </tr>
                                    </>
                                    }
                                </>
                            }
                        </>
                    </thead>
                    <tbody>
                        {
                            !isRowOrColumn ?
                                <>
                                    {
                                        subSection.kpis && subSection.kpis.map((kpi, index) => {
                                            return (
                                                <ColumnSimpleGrouped
                                                    calculations={calculations}
                                                    subSectionGroupColumnInfo={subSectionGroupColumnInfo}
                                                    moduleObject={moduleObject}
                                                    formatValuesInTooltip={formatValuesInTooltip}
                                                    setNumber={setNumber}
                                                    setText={setText}
                                                    funcCollapse={funcCollapse}
                                                    setComment={setComment}
                                                    uploadFile={uploadFile}
                                                    getFile={getFile}
                                                    numberOfRows={numberOfRows}
                                                    userRoles={userRoles}
                                                    showDescriptionColumn={showDescriptionColumn}
                                                    showUnitColumn={showUnitColumn}
                                                    showSecondaryUnitsColumn={showSecondaryUnitsColumn}
                                                    collapse={collapse}
                                                    collapseText={collapseText}
                                                    collapseCol={collapseCol}
                                                    row={index}
                                                    blockedField={blockedField}
                                                    addNewLine={addNewLine}
                                                    removeLastLine={removeLastLine}
                                                    removeActionPlanLine={removeActionPlanLine}
                                                    MODULE_ID={MODULE_ID}
                                                    setChatRequest={setChatRequest}
                                                    setShowChat={setShowChat}
                                                    defaultChatRequest={defaultChatRequest}
                                                    chatRequest={{ ...chatRequest }}
                                                    kpiDiscussions={kpiDiscussions}
                                                    kpiEvidences={kpiEvidences}
                                                    setKPIEvidences={setKPIEvidences}
                                                    tobaccoTypeID={tobaccoTypeID}
                                                    subSectionKPIsToBlock={subSectionKPIsToBlock}
                                                    setSubSectionKPIsToBlock={setSubSectionKPIsToBlock}
                                                    loading={loading}
                                                    kpi={kpi}
                                                    totalColumns={totalColumns}
                                                    setModuleObject={setModuleObject}
                                                    toleranceValidations={toleranceValidations}
                                                    subSectionKPIs={subSectionKPIs}
                                                    changeSelectedTobaccoType={changeSelectedTobaccoType}
                                                    selectedTobaccoTypes={selectedTobaccoTypes}
                                                    changeSelectedCountry={changeSelectedCountry}
                                                    selectedCountries={selectedCountries}
                                                    changeSelectedFuelType={changeSelectedFuelType}
                                                    selectedFuelTypes={selectedFuelTypes}
                                                    columnRows={subSection.columnRows}
                                                    reordenateColumns={reordenateColumns}
                                                    loadRefNumbersAndCalculations={loadRefNumbersAndCalculations}
                                                    rejectedKpisList={rejectedKpisList}
                                                    defaultValues={defaultValues}
                                                    fixedKpisList={fixedKpisList}
                                                    subSectionID={subSection.subSectionID}
                                                    defaultEvidenceRequest={defaultEvidenceRequest}
                                                    evidenceRequest={{ ...evidenceRequest }}
                                                    setEvidenceRequest={setEvidenceRequest}
                                                    setShowUploadEvidence={setShowUploadEvidence}
                                                    waiverIdentifier={waiverIdentifier}
                                                    replaceScore={replaceScore}
                                                />)
                                        })
                                    }
                                </>
                                :
                                <>
                                    {/* add uma variavel de controle para o numero de row para serem renderizadas */}
                                    {[...Array(numberOfRows < 0 ? 0 : numberOfRows)].map((x, row) => {
                                        return <>
                                            <tr key={`KPI_${subSection.subSectionID}_r${row}`} className={`row-${row}`}>
                                                <td width={'2%'}>
                                                    <TooltipComponent
                                                        style={{ backgroundColor: subSection?.kpis[0]?.guidance ? "#112A61" : "#e2e2e2" }}
                                                        guidance={subSection?.kpis[0]?.guidance}
                                                        size={14}
                                                    />
                                                </td>
                                                {
                                                    subSection.kpis ? subSection.kpis.map((kpi, index) => {
                                                        return (
                                                            <RowSimpleGrouped
                                                                calculations={calculations}
                                                                moduleObject={moduleObject}
                                                                formatValuesInTooltip={formatValuesInTooltip}
                                                                setNumber={setNumber}
                                                                setText={setText}
                                                                funcCollapse={funcCollapse}
                                                                setComment={setComment}
                                                                uploadFile={uploadFile}
                                                                getFile={getFile}
                                                                kpis={kpis}
                                                                numberOfRows={numberOfRows}
                                                                userRoles={userRoles}
                                                                showDescriptionColumn={showDescriptionColumn}
                                                                showUnitColumn={showUnitColumn}
                                                                showSecondaryUnitsColumn={showSecondaryUnitsColumn}
                                                                collapse={collapse}
                                                                collapseText={collapseText}
                                                                collapseRow={collapseRow}
                                                                row={row}
                                                                blockedField={blockedField}
                                                                defaultValues={defaultValues}
                                                                addNewLine={addNewLine}
                                                                removeLastLine={removeLastLine}
                                                                MODULE_ID={MODULE_ID}
                                                                setChatRequest={setChatRequest}
                                                                setShowChat={setShowChat}
                                                                chatRequest={{ ...chatRequest }}
                                                                kpiDiscussions={kpiDiscussions}
                                                                tobaccoTypeID={tobaccoTypeID}
                                                                subSectionKPIsToBlock={subSectionKPIsToBlock}
                                                                setSubSectionKPIsToBlock={setSubSectionKPIsToBlock}
                                                                loading={loading}
                                                                kpi={kpi}
                                                                setModuleObject={setModuleObject}
                                                                toleranceValidations={toleranceValidations}
                                                                subSectionKPIs={subSectionKPIs}
                                                                changeSelectedTobaccoType={changeSelectedTobaccoType}
                                                                selectedTobaccoTypes={selectedTobaccoTypes}
                                                                changeSelectedCountry={changeSelectedCountry}
                                                                selectedCountries={selectedCountries}
                                                                changeSelectedFuelType={changeSelectedFuelType}
                                                                selectedFuelTypes={selectedFuelTypes}
                                                                changeSelectedCuringBarn={changeSelectedCuringBarn}
                                                                selectedCuringBarns={selectedCuringBarns}
                                                                columnRows={subSection.columnRows}
                                                                subSection={subSection}
                                                                subSectionIsActionPlan={subSection.isActionPlan}
                                                                reordenateColumns={reordenateColumns}
                                                                setSelectedSSK={setSelectedSSK}
                                                                loadRefNumbersAndCalculations={loadRefNumbersAndCalculations}
                                                                rejectedKpisList={rejectedKpisList}
                                                                fixedKpisList={fixedKpisList}
                                                                replaceScore={replaceScore}
                                                            />
                                                        )
                                                    })
                                                        :
                                                        <></>
                                                }
                                                {
                                                    moduleObject.isEditable == true && !subSection.isNotShowAddDeleteButtons ?
                                                        <><td key={`KPIColumnRow_del`}>
                                                            <div className={"col-1"} style={{ padding: 0 }}><AiOutlineDelete size={20} onClick={(e) => removeLastLine(subSection, row)} />
                                                            </div>
                                                        </td></>

                                                        : <></>
                                                }
                                            </tr>
                                            {
                                                selectedSSK &&
                                                [...Array(1)].map((x) => {
                                                    var unitMeasure = GetTypeInput(selectedSSK.unitMeasureID, selectedSSK.unitMeasure.type);
                                                    var isNumber = (unitMeasure.inputType == "number" || unitMeasure.inputType == "decimal") ? true : false;
                                                    var op = `subSectionKPI_${selectedSSK.subSectionKPIID}_${selectedSSK.columnRowID}_r${row}`;

                                                    var findValue = null;
                                                    var file = undefined;
                                                    var comment = "";
                                                    var value = null;
                                                    var country = null;
                                                    var disabledField = false;
                                                    var isFromFSMIntegration = null;
                                                    var targetColumn = moduleObject.columnRows.find(x => x.columnRowID == selectedSSK.columnRowID);

                                                    

                                                    if (numberOfRows > 0) {
                                                        findValue = moduleObject.answers.find(x => x.subSectionKPIID == selectedSSK.subSectionKPIID && x.refNumber == selectedSSK.refNumber && x.row == row);
                                                    } else {
                                                        findValue = moduleObject.answers.find(x => x.subSectionKPIID == selectedSSK.subSectionKPIID && x.refNumber == selectedSSK.refNumber);
                                                    }
                                                    if (findValue != undefined && findValue != null) {
                                                        file = findValue.file;
                                                        comment = findValue.comment ?? "";
                                                        value = isNumber ? findValue.numberValue : findValue.textValue;
                                                    }

                                                    if(hasActionPlanModule && value == "Concluded") {
                                                        disabledField = true;
                                                    }

                                                    var sskEvidence = kpiEvidences.find(x => x.subSectionKPIID == selectedSSK.subSectionKPIID && x.row == row)

                                                    return (
                                                        <Collapse in={collapse == selectedSSK.subSectionKPIID && collapseRow == row} unmountOnExit={false}>
                                                            <tr style={{ verticalAlign: "top", backgroundColor: "rgb(250 250 250)" }}>
                                                                <td colSpan={totalColumns + 3} style={{ textAlign: "left", padding: 25 }}>
                                                                    <div className='row'>
                                                                        <div className="col-lg-6">
                                                                            <p>
                                                                                Comment {selectedSSK.comment == "Mandatory" && <span className="required-kpi">*</span>}
                                                                            </p>
                                                                            <CommentTextArea
                                                                                name={op + "_commentText"}
                                                                                id={op + "_commentText"}
                                                                                rows="5"
                                                                                style={{ margin: 0, width: '100%', backgroundColor: '#fff' }}
                                                                                defaultValue={comment}
                                                                                disabled={(moduleObject.isEditable && (findValue != undefined && findValue != null)) ? false : true}
                                                                                className={moduleObject.isEditable ? "" : "block-comment"}
                                                                                onBlur={(e) => setComment(
                                                                                    {
                                                                                        'subSectionKPI': selectedSSK,
                                                                                        'ref': selectedSSK.refNumber,
                                                                                        'value': e.target.value,
                                                                                        'row': row,
                                                                                        'moduleObject': moduleObject,
                                                                                        'setModuleObject': setModuleObject,
                                                                                        'subSectionKPIs': subSectionKPIs,
                                                                                    })
                                                                                }
                                                                            ></CommentTextArea>
                                                                        </div>

                                                                        <div className="col-lg-2">
                                                                            <ChatButton
                                                                                kpiDiscussions={kpiDiscussions}
                                                                                setChatRequest={setChatRequest}
                                                                                setShowChat={setShowChat}
                                                                                request={
                                                                                    {
                                                                                        ...defaultChatRequest,
                                                                                        "subSectionKPIID": selectedSSK.subSectionKPIID,
                                                                                        "kpiId": selectedSSK.kpiid,
                                                                                        "row": row,
                                                                                        "countryId": COLUMN_ROW_TYPES.Country == targetColumn.type ? selectedCountries[row]?.value : null,
                                                                                    }
                                                                                }
                                                                            />
                                                                            {/* <EvidenceButton
                                                                                kpiEvidences={kpiEvidences}
                                                                                setEvidenceRequest={setEvidenceRequest}
                                                                                setShowUploadEvidence={setShowUploadEvidence}
                                                                                isMandatory={selectedSSK.evidence == "Mandatory" ? true : false}
                                                                                moduleObject={moduleObject}
                                                                                evidenceRequest={
                                                                                    {
                                                                                        ...defaultEvidenceRequest,
                                                                                        "subSectionKPIID": selectedSSK.subSectionKPIID,
                                                                                        "row": row,
                                                                                        "fuelTypeId": COLUMN_ROW_TYPES.FuelType == targetColumn.type ? selectedFuelTypes.find(x => x.index == row && x.subSectionID == selectedSSK.subSectionID)?.value : null,
                                                                                        "curingBarnID": COLUMN_ROW_TYPES.FuelType == targetColumn.type ? selectedCuringBarns.find(x => x.index == row && x.subSectionID == selectedSSK.subSectionID)?.value : null,
                                                                                        "countryId": COLUMN_ROW_TYPES.Country == targetColumn.type ? selectedCountries[row]?.value : null,
                                                                                        "subSectionKPIValue": value ?? 0,
                                                                                        "file": sskEvidence?.file,
                                                                                        "value": sskEvidence?.value,
                                                                                        "farmersCount": sskEvidence?.farmersCount,
                                                                                        "isValidated": sskEvidence?.isValidated
                                                                                    }
                                                                                }
                                                                            /> */}
                                                                        </div>
                                                                        <div className="col-lg-4">
                                                                            <p>
                                                                                Files {selectedSSK.evidence == "Mandatory" && <span className="required-kpi">*</span>}
                                                                            </p>
                                                                            {(moduleObject.isEditable && userRoles.includes("RoleInfoHolder")) || disabledField && <><div className="upload-btn-wrapper">
                                                                                <button id={op + "_file"} className="btn-custom" disabled={(findValue != undefined && findValue != null) ? false : true}>Upload a file (need to save the form)</button>
                                                                                <input type="file" disabled={(findValue != undefined && findValue != null) ? false : true} onChange={(files) => uploadFile({
                                                                                    'subSectionKPI': selectedSSK,
                                                                                    'ref': selectedSSK.refNumber,
                                                                                    'files': files,
                                                                                    'row': row,
                                                                                    'moduleObject': moduleObject,
                                                                                    'setModuleObject': setModuleObject,
                                                                                    'selectedTobaccoTypes': selectedTobaccoTypes,
                                                                                    'subSectionKPIs': subSectionKPIs,
                                                                                })} />
                                                                            </div><br /></>}
                                                                            {
                                                                                file != "" && file != null && (file.length > 0 && file.filter(e => !e.isDeleted).length > 0) ?
                                                                                    Array.isArray(file) ?
                                                                                        file.filter(e => !e.isDeleted).map(e => {
                                                                                            return (
                                                                                                <>
                                                                                                    {e.file.toLowerCase().includes("docx")
                                                                                                        ?
                                                                                                        <AiFillFileWord style={{ marginLeft: 20 }} title={e.file} size={25} onClick={() => getFile(e.file)}></AiFillFileWord>
                                                                                                        :
                                                                                                        e.file.toLowerCase().includes("pdf")
                                                                                                            ?
                                                                                                            <AiFillFilePdf style={{ marginLeft: 20 }} title={e.file} size={25} onClick={() => getFile(e.file)}></AiFillFilePdf>
                                                                                                            :
                                                                                                            e.file.toLowerCase().includes("png") || e.file.toLowerCase().includes("jpg") || e.file.toLowerCase().includes("jfif")
                                                                                                                ?
                                                                                                                <AiFillFileImage style={{ marginLeft: 20 }} title={e.file} size={25} onClick={() => getFile(e.file)}></AiFillFileImage>
                                                                                                                :
                                                                                                                e.file.toLowerCase().includes("xlsx")
                                                                                                                    ?
                                                                                                                    <AiFillFileExcel style={{ marginLeft: 20 }} title={e.file} size={25} onClick={() => getFile(e.file)}></AiFillFileExcel>
                                                                                                                    :
                                                                                                                    <FaFileAlt style={{ marginLeft: 20 }} title={e.file} size={25} onClick={() => getFile(e.file)}></FaFileAlt>
                                                                                                    }
                                                                                                    {userRoles.includes("RoleInfoHolder") &&
                                                                                                        <AiFillCloseCircle className='delete-button' size={15} onClick={() => {
                                                                                                            removeFile({
                                                                                                                'subSectionKPI': selectedSSK,
                                                                                                                'ref': selectedSSK.refNumber,
                                                                                                                'value': e.file,
                                                                                                                'row': row,
                                                                                                                'moduleObject': moduleObject,
                                                                                                                'setModuleObject': setModuleObject,
                                                                                                                'selectedTobaccoTypes': selectedTobaccoTypes,
                                                                                                                'subSectionKPIs': subSectionKPIs,
                                                                                                                'deleteFile': true
                                                                                                            })

                                                                                                        }}></AiFillCloseCircle>
                                                                                                    }
                                                                                                </>
                                                                                            )
                                                                                        })
                                                                                        : file

                                                                                    : <>No file attached</>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </Collapse>
                                                    )
                                                })
                                            }

                                        </>
                                    })}
                                    {
                                        subSection.maxRowsQuantity > 0 && numberOfRows >= subSection.maxRowsQuantity ?
                                            <></>
                                            :
                                            moduleObject.isEditable == true && !subSection.isNotShowAddDeleteButtons ?
                                                <>
                                                    <tr width="100%">
                                                        <td colSpan={4} style={{ padding: '1rem' }}>
                                                            <a style={{ backgroundColor: '#50AF47', padding: '0.5rem 1rem', borderRadius: '3px', color: '#FFF', cursor: 'pointer' }} onClick={() => addNewLine(subSection)}>Add +</a>
                                                        </td>
                                                    </tr>
                                                </>
                                                : <></>

                                    }
                                </>
                        }

                    </tbody>
                </Table>
            </div>
        </Container>
    )
}

export default GroupedByColumnRowTable;
