//React Imports
import React, { useEffect, useState } from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import NumberFormat from 'react-number-format';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import Collapse from 'react-bootstrap/Collapse';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { MdChat } from 'react-icons/md';
import InputMask from 'react-input-mask';

//Components
import TooltipComponent from '../TooltipComponent';
import TooltipAlertComponent from '../TooltipAlertComponent';
import GetTypeInput from '../FormInput/set';
import FormInput from '../FormInput/index';
import { CommentTextArea, Field, IconNewDiscussion } from './styles';
import ChatButton from '../ChatButton/index';

//Style
import { FaTrashAlt } from 'react-icons/fa';

const RowSimpleGrouped = ({
    moduleObject, setModuleObject, setNumber, setText, funcCollapse, collapse,
    userRoles, collapseRow, numberOfRows, row, blockedField, kpis, subSection, replaceScore,
    kpiDiscussions, subSectionKPIsToBlock, setSubSectionKPIsToBlock, loading, kpi, subSectionKPIs, setSelectedSSK, selectedTobaccoTypes, selectedCountries,
    changeSelectedFuelType, columnRows, reordenateColumns, loadRefNumbersAndCalculations, rejectedKpisList, fixedKpisList, subSectionIsActionPlan, defaultValues
}) => {
    const [userObject, setUserObject] = useState(JSON.parse(localStorage.getItem('@QualityApp:user')));
    const [handleChangeTime, sethandleChangeTime] = useState(null);

    const collapseByRow = (ssk, name) => {
        funcCollapse(ssk.subSectionKPIID, name, row, null);
        setSelectedSSK(ssk);
    }

    useEffect(() => {
        if(handleChangeTime != null) {           
            handleSetChangeTime(handleChangeTime.ssk, handleChangeTime.ref, handleChangeTime.time, handleChangeTime.row, moduleObject, setModuleObject, subSectionKPIs, subSectionKPIsToBlock, setSubSectionKPIsToBlock )            
        }
        
         
    },[handleChangeTime])

    const handleSetChangeTime = (
        ssk,
        ref,
        time,
        row,
        moduleObject,
        setModuleObject,
        subSectionKPIs,
        subSectionKPIsToBlock,
        setSubSectionKPIsToBlock
    ) => {
       
        if (time && time !== '__:__:__') {
            // Split the input value into hours, minutes, and seconds
            const [hours, minutes, seconds] = time.split(':').map(Number);

            // if((!isNaN(hours) && hours <= 23) || (!isNaN(minutes) && minutes <= 59) || (!isNaN(seconds) && seconds <= 59)) {
                // If within limits, call the onChange prop
                setText({
                    'subSectionKPI': ssk,
                    'ref': ref,
                    'value': time,
                    'row': row,
                    'moduleObject': moduleObject,
                    'setModuleObject': setModuleObject,
                    'subSectionKPIs': subSectionKPIs,
                    'subSectionKPIsToBlock': subSectionKPIsToBlock,
                    'setSubSectionKPIsToBlock': setSubSectionKPIsToBlock
                })
            // }
        }
        else if(time === '__:__:__') {
            setText({
                'subSectionKPI': ssk,
                'ref': ref,
                'value': null,
                'row': row,
                'moduleObject': moduleObject,
                'setModuleObject': setModuleObject,
                'subSectionKPIs': subSectionKPIs,
                'subSectionKPIsToBlock': subSectionKPIsToBlock,
                'setSubSectionKPIsToBlock': setSubSectionKPIsToBlock
            })
        }
    };

    function getFormattedDate(date) {
        var year = date.getFullYear();

        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        return month + '/' + day + '/' + year;
    }

    const formatValuesInTooltip = ({op, value, decimalSeparator, decimalScale, suffix, isEditable, isCalculation, isHighlight, standardInvalid}) => {
        return (
            standardInvalid ?
            <Field>
                    <textarea
                        name={op}
                        id={op}
                        className={standardInvalid == true ? "invalid" : ""}
                        style={{backgroundColor: '#ffd3d3', padding: '0.5rem', border: 0, width: '100%'}}        
                        rows={2}                                                   
                    >
                        {value}
                    </textarea>                                                            
                </Field>
            :
            isCalculation || (value != null && typeof (value) == "number") ?
                <NumberFormat
                    value={value}
                    thousandsGroupStyle="thousand"
                    decimalSeparator={decimalSeparator}
                    decimalScale={decimalScale}
                    suffix={suffix}
                    style={isHighlight ? { border: "2px solid #FBBA00" } : {}}
                    displayType="input"
                    type="text"
                    disabled={isEditable ? false : true}
                    className={!isEditable && isCalculation ? "read-only-total" : "read-only-rr"}
                    thousandSeparator={true}
                    allowNegative={true}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={true}
                />
                :
                <Field>
                    <span
                        name={op}
                        id={op}
                        className={standardInvalid == true ? "invalid" : ""}                                                              
                    >
                        {value}
                    </span>                                                            
                </Field>
        )
    }

    const IconDiscussionToAnswer = (kpiDiscussion) => {
        if (kpiDiscussion != null) {
            if (kpiDiscussion.messages != null && kpiDiscussion.messages != undefined) {
                if (kpiDiscussion.messages.length > 0) {
                    if (kpiDiscussion.messages[kpiDiscussion.messages.length - 1].createdBy != userObject.id) {
                        if (kpiDiscussion.lastDiscussionStatus == "Open")
                            return (<IconNewDiscussion className='icon-newdiscussion'></IconNewDiscussion>)
                    }
                }
            }
        }
    }

    const formatValuesInTooltipNumber = (value, decimalSeparator, decimalScale, suffix, isCalculation) => {
        return (
            isCalculation || (value != null && typeof (value) == "number") ?
                <NumberFormat
                    value={value}
                    thousandsGroupStyle="thousand"
                    decimalSeparator={decimalSeparator}
                    decimalScale={decimalScale}
                    suffix={suffix}
                    displayType="text"
                    type="text"
                    thousandSeparator={true}
                    allowNegative={true}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={true}
                />
                :
                <>{value}</>
        )
    }

    return <>
        {
            reordenateColumns(columnRows).filter(x => x.isParentColumn == false).map((columnRow, i) => {
                var sskpi = kpi.subSectionKPIs.find(x => x.columnRowID == columnRow.columnRowID);
                if (sskpi) {
                    var unitMeasure = GetTypeInput(sskpi.unitMeasureID, sskpi.unitMeasure.type);
                    var isNumber = (unitMeasure.inputType == "number" || unitMeasure.inputType == "decimal") ? true : false;
                    var calculation = sskpi.inputCalc == "Calculation" || sskpi.inputCalc == "DisplayText" ? sskpi.calculation : null;
                    var isCalculation = false;
                    var isDisplayText = false;
                    var alertMessage = sskpi.alertMessage;

                    var op = `subSectionKPI_${sskpi.subSectionKPIID}_${sskpi.columnRowID}_r${row}`


                    if (calculation != null && sskpi.inputCalc == "Calculation") {
                        isCalculation = true;
                    }

                    if (calculation != null && sskpi.inputCalc == "DisplayText") {
                        isDisplayText = true;
                    }


                    var findValue = null;
                    var file = undefined;
                    var comment = "";
                    var value = null;
                    var country = null;
                    var disabledField = false;
                    var standardInvalid = false;
                    var isFromFSMIntegration = null;
                    var highlightField = false;

                    if (numberOfRows > 1) {
                        findValue = moduleObject.answers.find(x => x.subSectionKPIID == sskpi.subSectionKPIID && x.refNumber == sskpi.refNumber && x.row == row && x.subSectionID == sskpi.subSectionID);
                    } else {
                        findValue = moduleObject.answers.find(x => x.subSectionKPIID == sskpi.subSectionKPIID && x.refNumber == sskpi.refNumber);
                    }

                    if (findValue != undefined && findValue != null) {
                        value = isNumber ? findValue.numberValue : findValue.textValue;
                        file = findValue.file;
                        comment = findValue.comment;
                        isFromFSMIntegration = findValue.isFromFSMIntegration;
                        standardInvalid = findValue.standardInvalid;
                    }

                    var hasDiscussion = kpiDiscussions.find(x => x.subSectionKPIID == sskpi.subSectionKPIID);
                    var discussionCollor = "";
                    if (hasDiscussion) {
                        if ((hasDiscussion?.column != null || hasDiscussion?.row != null)) {
                            if ((hasDiscussion?.column != null ? hasDiscussion?.column == null : true) && (hasDiscussion?.row != null ? hasDiscussion?.row == i : true)) {
                                discussionCollor = hasDiscussion.lastDiscussionStatus == "Open" ? "#FFDA7E" : hasDiscussion.lastDiscussionStatus == "Closed" ? "#ABF0B0" : "";
                            } else {
                                hasDiscussion = null;
                            }
                        } else {
                            discussionCollor = hasDiscussion.lastDiscussionStatus == "Open" ? "#FFDA7E" : hasDiscussion.lastDiscussionStatus == "Closed" ? "#ABF0B0" : "";
                        }
                    }


                    if (columnRow.type == 2) {
                        if (selectedCountries[row] == null || selectedCountries[row]?.text == "") {
                            disabledField = true
                        }
                        if (subSectionKPIsToBlock && subSectionKPIsToBlock?.some(x => x.refNumber == sskpi.refNumber && x.row == row)) {
                            disabledField = true
                        }
                        if (columnRow.kpiReference == sskpi.refNumber && !inputEditable) {
                            let auxVal = value;
                            value = selectedCountries[row]?.text;
                            if (value == "" || value == null) {
                                var arrayCountry = [...selectedCountries];
                                var countrie = arrayCountry?.find(x => x.value == auxVal);
                                if (countrie != null) {
                                    value = countrie?.text;
                                }

                            }
                        }
                    }
                    else if (columnRow.type == 4) {
                        if (selectedTobaccoTypes[row] == null || selectedTobaccoTypes[row]?.text == "") {
                            disabledField = true
                        }

                        if (columnRow.kpiReference == sskpi.refNumber && !inputEditable) {
                            value = selectedTobaccoTypes[row]?.text;
                        }
                    }
                    else if (isCalculation || isDisplayText) {
                        disabledField = true
                    }

                    for (var refNumber in moduleObject.dynamicBlockKpis) {
                        if (refNumber != sskpi.refNumber) {
                            moduleObject.dynamicBlockKpis[refNumber].forEach(element => {
                                var findRefNumber = element.refNumbersToBlock.find(x => x == sskpi.refNumber)
                                var answerToReference = moduleObject.answers.find(x => x.refNumber == refNumber && x.row == row)
                                if (findRefNumber == sskpi.refNumber) {
                                    if (findRefNumber && answerToReference != null) {
                                        if (answerToReference.numberValue != null ? answerToReference.numberValue == element.answerToBlock : answerToReference.textValue == element.answerToBlock) {
                                            disabledField = true
                                        }
                                    }
                                    else {
                                        disabledField = true
                                    }
                                }

                            });
                        }

                    }

                    //TODO - Set diasbledField based on reject Partial status
                    if (rejectedKpisList != null && rejectedKpisList?.length > 0) {
                        disabledField = true
                    }

                    if (rejectedKpisList?.some(x => x.subSectionKPIID == sskpi.subSectionKPIID && x.rowIndex == row)) {
                        disabledField = false
                    }

                    var userObj = JSON.parse(localStorage.getItem('@QualityApp:user'));
                    var isApproving = false;
                    //Validate if user is  or global
                    if ((moduleObject?.version?.status == 2 && userObj?.companyID != null && userRoles.includes("RoleApprover")) || (moduleObject?.version?.status == 4 && userObj?.companyID == null && userRoles.includes("RoleApprover"))) {
                        isApproving = true;
                    }

                    if (fixedKpisList?.some(x => x.subSectionKPIID == sskpi.subSectionKPIID && x.rowIndex == row) && !inputEditable && isApproving) {
                        highlightField = true;
                    }

                    var isColumnOverload = true;


                    if (defaultValues != null && defaultValues?.length > 0) {
                        var answerSSKPI = null
                        var kpiId = null
                        kpis.forEach(kpi => {
                            answerSSKPI = moduleObject.answers.find(x => x.subSectionID == sskpi.subSectionID && x.row == row && x.textValue == kpi.description);
                            if (answerSSKPI) {
                                kpiId = kpi.kpiid
                            }
                        })
                        if (kpiId) {
                            var refNumberReference = subSectionKPIs.find(e => e.kpiid == kpiId)?.refNumber
                            if (refNumberReference) {
                                var defaultValue = defaultValues.find(val => val.subSectionKPIID == sskpi.subSectionKPIID && val.refNumberReference == refNumberReference);
                                if (defaultValue?.disableInput) {
                                    disabledField = true;
                                }

                                var existsAnswer = moduleObject.answers.find(x => x.subSectionKPIID == sskpi.subSectionKPIID && x.row == row);
                                if (defaultValue && !existsAnswer) {
                                    var updateAnswer = false;
                                    var valueToUse = defaultValue.numberValue != null ? defaultValue.numberValue : defaultValue.textValue;
                                    //Generates Answer
                                    if (existsAnswer != null) {
                                        var existentAnswerValue = existsAnswer.numberValue ? existsAnswer.numberValue : existsAnswer.textValue;
                                        updateAnswer = existentAnswerValue != valueToUse;
                                    }
                                    var isString = true;
                                    if (typeof valueToUse === 'number') {
                                        isString = false; // It can be cast to an integer
                                    }


                                    if (existsAnswer == null || (updateAnswer && defaultValue.disableInput)) {
                                        value = valueToUse;
                                        if (!isString) {
                                            setNumber({
                                                'subSectionKPI': sskpi,
                                                'ref': sskpi.refNumber,
                                                'value': value,
                                                'row': row,
                                                'moduleObject': moduleObject,
                                                'setModuleObject': setModuleObject,
                                                'subSectionKPIs': subSectionKPIs,
                                                'subSectionKPIsToBlock': subSectionKPIsToBlock,
                                                'setSubSectionKPIsToBlock': setSubSectionKPIsToBlock
                                            })
                                        } else {
                                            setText({
                                                'subSectionKPI': sskpi,
                                                'ref': sskpi.refNumber,
                                                'value': value,
                                                'row': row,
                                                'moduleObject': moduleObject,
                                                'setModuleObject': setModuleObject,
                                                'subSectionKPIs': subSectionKPIs,
                                                'subSectionKPIsToBlock': subSectionKPIsToBlock,
                                                'setSubSectionKPIsToBlock': setSubSectionKPIsToBlock
                                            })
                                        }
                                    }
                                }
                            }
                        }
                    }

                    var inputEditable = moduleObject.isEditable;

                    if(subSection.isActionPlan && userRoles.includes("RoleInfoHolder") && moduleObject.version != null && moduleObject.version?.status != 7) {
                        inputEditable = true;
                    }

                    if (sskpi.unitMeasure.type == "Date" && value != null && inputEditable) {
                        value = new Date(moment(value, "DD/MM/YYYY"));
                    }


                    return <>
                        <td style={isColumnOverload ? { minWidth: "150px" } : {}} key={`KPIColumnRow_td_${i}`} colSpan={1}>
                            <div className="row" style={{ position: "relative", alignItems: 'center' }}>
                                <div className={"col-10"} style={{ padding: 0 }}>

                                    {
                                        inputEditable ?
                                            isNumber && !isCalculation ?
                                                <>
                                                    {loadRefNumbersAndCalculations(sskpi)}
                                                    {(sskpi.toleranceType) ? <TooltipAlertComponent id={`${op}_alert`} message={sskpi.alertMessage} className="alert-column hide" placement={"left"} /> : null}
                                                    <OverlayTrigger
                                                        delay={{ hide: 0, show: 100 }}
                                                        overlay={(props) => (
                                                            <Tooltip {...props}>
                                                                {(sskpi.inputMessage != null && sskpi.inputMessage != "" ? sskpi.inputMessage : value != null ? value : "")}
                                                            </Tooltip>
                                                        )}
                                                        placement={"left"}>
                                                        <Field>
                                                            <NumberFormat
                                                                name={op}
                                                                id={op}
                                                                autoComplete="off"
                                                                className={standardInvalid ? "invalid" : ""}
                                                                title={""}
                                                                thousandsGroupStyle="thousand"
                                                                value={value != null ? value : ""}
                                                                decimalSeparator={unitMeasure.decimalSeparator != "" ? unitMeasure.decimalSeparator : "."}
                                                                decimalScale={unitMeasure.decimalPrecision}
                                                                suffix={unitMeasure.suffix}
                                                                displayType="input"
                                                                type="text"
                                                                thousandSeparator={true}
                                                                allowNegative={true}
                                                                allowLeadingZeros={false}
                                                                allowEmptyFormatting={false}
                                                                placeholder="00.00"
                                                                disabled={disabledField}
                                                                style={highlightField ? { border: "2px solid #FBBA00" } : {} }
                                                                onValueChange={(values, sourceInfo) => {
                                                                    setNumber({
                                                                        'subSectionKPI': sskpi,
                                                                        'ref': sskpi.refNumber,
                                                                        'value': (values.formattedValue != "" ? parseFloat(values.floatValue) : ""),
                                                                        'row': row,
                                                                        'moduleObject': moduleObject,
                                                                        'setModuleObject': setModuleObject,
                                                                        'subSectionKPIs': subSectionKPIs,
                                                                        'subSectionKPIsToBlock': subSectionKPIsToBlock,
                                                                        'setSubSectionKPIsToBlock': setSubSectionKPIsToBlock
                                                                    })
                                                                }}
                                                            />
                                                            {
                                                                sskpi.mandatory == true &&
                                                                <div class="required-icon">
                                                                    <div class="text">*</div>
                                                                </div>
                                                            }
                                                        </Field>
                                                    </OverlayTrigger>
                                                </>

                                                :
                                                isNumber && isCalculation ?
                                                    <>
                                                        {loadRefNumbersAndCalculations(sskpi)}
                                                        {(sskpi.toleranceType) ? <TooltipAlertComponent id={`${op}_alert`} message={sskpi.alertMessage} className="alert-column hide" placement={"left"} /> : null}
                                                        <OverlayTrigger
                                                            delay={{ hide: 0, show: 100 }}
                                                            overlay={(props) => (
                                                                <Tooltip {...props}>
                                                                    {(sskpi.inputMessage != null && sskpi.inputMessage != "" ? sskpi.inputMessage : value != null ? value : "")}
                                                                </Tooltip>
                                                            )}
                                                            placement={"left"}>
                                                            <Field>
                                                                <NumberFormat
                                                                    name={op}
                                                                    id={op}
                                                                    autoComplete="off"
                                                                    className="read-only-total"
                                                                    disabled
                                                                    title={""}
                                                                    thousandsGroupStyle="thousand"
                                                                    value={value != null ? value : ""}
                                                                    decimalSeparator={unitMeasure.decimalSeparator != "" ? unitMeasure.decimalSeparator : "."}
                                                                    decimalScale={unitMeasure.decimalPrecision}
                                                                    suffix={value != null ? unitMeasure.suffix : ""}
                                                                    displayType="input"
                                                                    type="text"
                                                                    thousandSeparator={true}
                                                                    allowNegative={true}
                                                                    allowLeadingZeros={false}
                                                                    allowEmptyFormatting={true}
                                                                />
                                                                {
                                                                    sskpi.mandatory == true &&
                                                                    <div class="required-icon">
                                                                        <div class="text">*</div>
                                                                    </div>
                                                                }
                                                            </Field>
                                                        </OverlayTrigger>
                                                    </>
                                                    :
                                                    sskpi.unitMeasure.type === "selectfuel" ?
                                                        <>
                                                            {loadRefNumbersAndCalculations(sskpi)}
                                                            <TooltipAlertComponent id={`${op}_alert`} message={sskpi.alertMessage} className="alert-column hide" placement={"left"} />
                                                            <OverlayTrigger
                                                                delay={{ hide: 0, show: 100 }}
                                                                overlay={(props) => (
                                                                    <Tooltip {...props}>
                                                                        {(sskpi.inputMessage != null && sskpi.inputMessage != "" ? sskpi.inputMessage : value != null ? value : "")}
                                                                    </Tooltip>
                                                                )}
                                                                placement={"left"}>
                                                                <Field>
                                                                    {moduleObject.fuelTypesOptions.length > 0 &&
                                                                        <>
                                                                            <FormInput
                                                                                disabled={disabledField}
                                                                                options={moduleObject.fuelTypesOptions.filter(x => x.kpiRefNumber == sskpi.refNumber)}
                                                                                name={op}
                                                                                id={op}
                                                                                inputType={sskpi.unitMeasure.type}
                                                                                autoComplete="off"
                                                                                value={value != null ? value : ""}
                                                                                onChange={(f) => {
                                                                                    changeSelectedFuelType(f.target.value, sskpi, row, op)
                                                                                }}
                                                                            />
                                                                        </>

                                                                    }
                                                                    {
                                                                        sskpi.mandatory == true &&
                                                                        <div class="required-icon">
                                                                            <div class="text">*</div>
                                                                        </div>
                                                                    }
                                                                </Field>
                                                            </OverlayTrigger>
                                                        </>
                                                        :
                                                        unitMeasure.inputType == "date" ?
                                                            <>
                                                                {loadRefNumbersAndCalculations(sskpi)}
                                                                <TooltipAlertComponent id={`${op}_alert`} message={sskpi.alertMessage} className="alert-column hide" placement={"left"} />
                                                                <OverlayTrigger
                                                                    delay={{ hide: 0, show: 100 }}
                                                                    overlay={(props) => (
                                                                        (value != null) ? //validate overlay when field is text on Prompt Actions Modules
                                                                            <Tooltip {...props}>
                                                                                {(value != null ? moment(value).format("DD/MM/YYYY") : "")}
                                                                            </Tooltip>

                                                                            :
                                                                            <></>
                                                                    )}
                                                                    placement={"left"}>
                                                                    <Field>
                                                                        {
                                                                            <DatePicker
                                                                                name={op}
                                                                                id={op}
                                                                                autoComplete="off"
                                                                                selected={value != null ? value : ""}
                                                                                dateFormat="dd/MM/yyyy"
                                                                                className={`react-datepicker-popper ${inputEditable ? "react-datepicker-popper" : "read-only"}`}
                                                                                disabled={inputEditable ? (blockedField ? true : disabledField) : true}
                                                                                onChange={(date) => {
                                                                                    var formattedDate = date != null ? moment(date).format("DD/MM/YYYY") : "";

                                                                                    setText({
                                                                                        'subSectionKPI': sskpi,
                                                                                        'ref': sskpi.refNumber,
                                                                                        'value': formattedDate,
                                                                                        'row': row,
                                                                                        'moduleObject': moduleObject,
                                                                                        'setModuleObject': setModuleObject,
                                                                                        'subSectionKPIs': subSectionKPIs,
                                                                                        'subSectionKPIsToBlock': subSectionKPIsToBlock,
                                                                                        'setSubSectionKPIsToBlock': setSubSectionKPIsToBlock
                                                                                    });

                                                                                }}
                                                                            />
                                                                        }
                                                                        {
                                                                            sskpi.mandatory == true &&
                                                                            <div class="required-icon">
                                                                                <div class="text">*</div>
                                                                            </div>
                                                                        }
                                                                    </Field>
                                                                </OverlayTrigger>
                                                            </>
                                                            :
                                                            unitMeasure.inputType == "time" ?
                                                                <>
                                                                    {loadRefNumbersAndCalculations(sskpi)}
                                                                    <TooltipAlertComponent id={`${op}_alert`} message={sskpi.alertMessage} className="alert-column hide" placement={"left"} />
                                                                    <OverlayTrigger
                                                                        delay={{ hide: 0, show: 100 }}
                                                                        overlay={(props) => (
                                                                            (value != null) ? //validate overlay when field is text on Prompt Actions Modules
                                                                                <Tooltip {...props}>
                                                                                    {(value != null ? value : "")}
                                                                                </Tooltip>

                                                                                :
                                                                                <></>
                                                                        )}
                                                                        placement={"left"}>
                                                                        <Field>
                                                                            {

                                                                                <InputMask
                                                                                    name={op}
                                                                                    id={op}
                                                                                    mask="99:99:99"
                                                                                    placeholder="hh:mm:ss"
                                                                                    value={value != null ? value : ""}
                                                                                    className={`${inputEditable ? "react-datepicker-popper" : "read-only"} ${standardInvalid ? "invalid" : ""}`}
                                                                                    disabled={inputEditable ? (blockedField ? true : disabledField) : true}
                                                                                    onChange={(time) => {

                                                                                        sethandleChangeTime({
                                                                                            'ssk': sskpi,
                                                                                            'ref': sskpi.refNumber,
                                                                                            'time': time.target.value,
                                                                                            'row': row,
                                                                                            'moduleObject': moduleObject,
                                                                                            'setModuleObject': setModuleObject,
                                                                                            'subSectionKPIs': subSectionKPIs,
                                                                                            'subSectionKPIsToBlock': subSectionKPIsToBlock,
                                                                                            'setSubSectionKPIsToBlock': setSubSectionKPIsToBlock
                                                                                        })

                                                                                    }}
                                                                                />
                                                                            }
                                                                            {
                                                                                sskpi.mandatory == true &&
                                                                                <div class="required-icon">
                                                                                    <div class="text">*</div>
                                                                                </div>
                                                                            }
                                                                        </Field>
                                                                    </OverlayTrigger>
                                                                </>
                                                                :
                                                                unitMeasure.inputType == "input" || unitMeasure.inputType == "" || unitMeasure.inputType == null ?
                                                                    <>
                                                                        {loadRefNumbersAndCalculations(sskpi)}
                                                                        <OverlayTrigger
                                                                            delay={{ hide: 0, show: 100 }}
                                                                            overlay={(props) => (
                                                                                //validate overlay when field is text on Prompt Actions Modules
                                                                                <Tooltip {...props}>
                                                                                    {(sskpi.inputMessage != null && sskpi.inputMessage != "" ? sskpi.inputMessage : value != null ? value : "")}
                                                                                </Tooltip>
                                                                            )}
                                                                            placement={"left"}>
                                                                            <Field>
                                                                                <input
                                                                                    name={op}
                                                                                    id={op}
                                                                                    autoComplete="off"
                                                                                    style={isFromFSMIntegration == true ? { border: "2px solid #50af47" } : highlightField ? { border: "2px solid #FBBA00" } : {}}
                                                                                    value={value != null ? value : ""}
                                                                                    title={inputEditable ? sskpi.inputMessage : ""}
                                                                                    type={"text"}
                                                                                    className={`${isDisplayText ? "read-only-total" : ""} ${standardInvalid ? "invalid" : ""}`}
                                                                                    disabled={inputEditable ? blockedField ? true : disabledField : true}
                                                                                    onChange={(f) => {
                                                                                        if (!loading) {
                                                                                            setText({
                                                                                                'subSectionKPI': sskpi,
                                                                                                'ref': sskpi.refNumber,
                                                                                                'value': f.target.value,
                                                                                                'row': row,
                                                                                                'moduleObject': moduleObject,
                                                                                                'setModuleObject': setModuleObject,
                                                                                                'subSectionKPIs': subSectionKPIs,
                                                                                                'subSectionKPIsToBlock': subSectionKPIsToBlock,
                                                                                                'setSubSectionKPIsToBlock': setSubSectionKPIsToBlock
                                                                                            })
                                                                                        }

                                                                                    }
                                                                                    }
                                                                                />
                                                                                {
                                                                                    sskpi.mandatory == true &&
                                                                                    <div class="required-icon">
                                                                                        <div class="text">*</div>
                                                                                    </div>
                                                                                }
                                                                            </Field>
                                                                        </OverlayTrigger>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {loadRefNumbersAndCalculations(sskpi)}
                                                                        <OverlayTrigger
                                                                            delay={{ hide: 0, show: 100 }}
                                                                            overlay={(props) => (
                                                                                <Tooltip {...props}>
                                                                                    {(sskpi.inputMessage != null && sskpi.inputMessage != "" ? sskpi.inputMessage : value != null ? value : "")}
                                                                                </Tooltip>
                                                                            )}
                                                                            placement={"left"}>
                                                                            <Field>
                                                                                <FormInput
                                                                                    name={op}
                                                                                    id={op}
                                                                                    autoComplete="off"
                                                                                    value={value != null ? value : ""}
                                                                                    className={standardInvalid ? "invalid" : ""}
                                                                                    style={highlightField ? { border: "2px solid #FBBA00" } : {}}
                                                                                    inputType={unitMeasure.inputType != null && unitMeasure.inputType != "" ? unitMeasure.inputType : "text"}
                                                                                    placeholder={unitMeasure.inputType == "selectcustom" ? { value: "", text: "Select an option" } : null}
                                                                                    customOptions={sskpi.customOptions}
                                                                                    disabled={disabledField}
                                                                                    checked={unitMeasure.inputType == "radio" ? (value === "true") ? true : null : null}
                                                                                    onChange={(f) => {
                                                                                        var newModuleObject = null
                                                                                        if (subSectionIsActionPlan && f.target.value == "Concluded") {
                                                                                            var columnRow = columnRows.find(e => e.name.includes("Score"))
                                                                                            if (columnRow) {
                                                                                                var sskpiScore = kpi.subSectionKPIs.find(e => e.columnRowID == columnRow.columnRowID)
                                                                                                if (sskpiScore) {
                                                                                                    newModuleObject = setNumber({
                                                                                                        'subSectionKPI': sskpiScore,
                                                                                                        'ref': sskpiScore.refNumber,
                                                                                                        'value': 3,
                                                                                                        'row': row,
                                                                                                        'moduleObject': moduleObject,
                                                                                                        'setModuleObject': setModuleObject,
                                                                                                        'subSectionKPIs': subSectionKPIs,
                                                                                                        'isActionPlan': true,
                                                                                                        'subSectionKPIsToBlock': subSectionKPIsToBlock,
                                                                                                        'setSubSectionKPIsToBlock': setSubSectionKPIsToBlock
                                                                                                    })
                                                                                                }
                                                                                            }
                                                                                        }else if(subSectionIsActionPlan && f.target.value != "Concluded") {
                                                                                            var columnRow = columnRows.find(e => e.name.includes("Score"))
                                                                                            if (columnRow) {
                                                                                                var sskpiScore = kpi.subSectionKPIs.find(e => e.columnRowID == columnRow.columnRowID)
                                                                                                var answer = moduleObject.answers.find(x => x.subSectionKPIID == sskpiScore.subSectionKPIID && x.row == row);
                                                                                                if(answer && answer.numberValue == 3) {
                                                                                                    newModuleObject = setNumber({
                                                                                                        'subSectionKPI': sskpiScore,
                                                                                                        'ref': sskpiScore.refNumber,
                                                                                                        'value': null,
                                                                                                        'row': row,
                                                                                                        'moduleObject': moduleObject,
                                                                                                        'setModuleObject': setModuleObject,
                                                                                                        'subSectionKPIs': subSectionKPIs,
                                                                                                        'isActionPlan': true,
                                                                                                        'subSectionKPIsToBlock': subSectionKPIsToBlock,
                                                                                                        'setSubSectionKPIsToBlock': setSubSectionKPIsToBlock
                                                                                                    })
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                        
                                                                                        setText({
                                                                                            'subSectionKPI': sskpi,
                                                                                            'ref': sskpi.refNumber,
                                                                                            'value': f.target.value,
                                                                                            'row': row,
                                                                                            'moduleObject': newModuleObject ? newModuleObject : moduleObject,
                                                                                            'setModuleObject': setModuleObject,
                                                                                            'subSectionKPIs': subSectionKPIs,
                                                                                            'subSectionKPIsToBlock': subSectionKPIsToBlock,
                                                                                            'setSubSectionKPIsToBlock': setSubSectionKPIsToBlock
                                                                                        })
                                                                                        replaceScore(row, newModuleObject ? newModuleObject : moduleObject)
                                                                                    }
                                                                                    }
                                                                                />
                                                                                {
                                                                                    sskpi.mandatory == true &&
                                                                                    <div class="required-icon">
                                                                                        <div class="text">*</div>
                                                                                    </div>
                                                                                }
                                                                            </Field>
                                                                        </OverlayTrigger>
                                                                    </>
                                            :
                                            unitMeasure.inputType == "textarea" ?
                                                <>
                                                    {loadRefNumbersAndCalculations(sskpi)}
                                                    <OverlayTrigger
                                                        delay={{ hide: 0, show: 100 }}
                                                        overlay={(props) => (
                                                            <Tooltip {...props}>
                                                                {(sskpi.inputMessage != null && sskpi.inputMessage != "" ? sskpi.inputMessage : value != null ? value : "")}
                                                            </Tooltip>
                                                        )}
                                                        placement={"left"}>
                                                        <Field>
                                                            <textarea
                                                                name={`kpiColumnRowID_${sskpi.kpiColumnRowID}_${row}`}
                                                                id={`kpiColumnRowID_${sskpi.kpiColumnRowID}_${row}`}
                                                                autoComplete="off"
                                                                style={{ width: '100%', background: 'none', border: 0, resize: 'none', verticalAlign: 'middle' }}
                                                                value={value != null ? value : ""}
                                                                className="read-only text-center"
                                                                disabled={disabledField}
                                                                rows="2"

                                                            />
                                                        </Field>
                                                    </OverlayTrigger>
                                                </>
                                                :
                                                isNumber ?
                                                    <>
                                                        {loadRefNumbersAndCalculations(sskpi)}
                                                        <OverlayTrigger
                                                            delay={{ hide: 0, show: 100 }}
                                                            overlay={(props) => (
                                                                <Tooltip {...props}>
                                                                    {(sskpi.inputMessage != null && sskpi.inputMessage != "" ? sskpi.inputMessage : value != null ? value : "")}
                                                                </Tooltip>
                                                            )}
                                                            placement={"left"}>
                                                            <div className='text-center'>
                                                                <Field>
                                                                    {formatValuesInTooltip({
                                                                        'op': op,
                                                                        'value': value,
                                                                        'decimalSeparator': unitMeasure.decimalSeparator,
                                                                        'decimalScale': unitMeasure.decimalPrecision,
                                                                        'suffix': unitMeasure.suffix,
                                                                        'isEditable': inputEditable,
                                                                        'isCalculation': isCalculation,
                                                                        'isHighlight': highlightField,
                                                                        'standardInvalid': standardInvalid
                                                                    })}
                                                                </Field>
                                                            </div>
                                                        </OverlayTrigger>
                                                    </>
                                                    :
                                                    (unitMeasure.inputType === "" || unitMeasure.inputType === "text" ?
                                                        <>
                                                            {loadRefNumbersAndCalculations(sskpi)}
                                                            <OverlayTrigger
                                                                delay={{ hide: 0, show: 100 }}
                                                                overlay={(props) => (
                                                                    <Tooltip {...props}>
                                                                        {(sskpi.inputMessage != null && sskpi.inputMessage != "" ? sskpi.inputMessage : value != null ? value : "")}
                                                                    </Tooltip>
                                                                )}
                                                                placement={"left"}>
                                                                <Field>
                                                                    <textarea
                                                                        name={op}
                                                                        id={op}
                                                                        autoComplete="off"
                                                                        style={isFromFSMIntegration == true ? { border: "2px solid #50af47" } : highlightField ? { border: "2px solid #FBBA00" } : {}}
                                                                        value={value != null ? value : ""}
                                                                        title={inputEditable ? sskpi.inputMessage : ""}
                                                                        inputType={"text"}
                                                                        className={(isNumber && isCalculation) || isDisplayText ? "read-only-total" : standardInvalid == true ? "invalid" : "read-only"}
                                                                        disabled={inputEditable ? false : true}

                                                                    />
                                                                </Field>
                                                            </OverlayTrigger>
                                                        </>
                                                        :
                                                        <>
                                                            {loadRefNumbersAndCalculations(sskpi)}
                                                            <OverlayTrigger
                                                                delay={{ hide: 0, show: 100 }}
                                                                overlay={(props) => (
                                                                    <Tooltip {...props}>
                                                                        {(sskpi.inputMessage != null && sskpi.inputMessage != "" ? sskpi.inputMessage : value != null ? value : "")}
                                                                    </Tooltip>
                                                                )}
                                                                placement={"left"}>

                                                                <Field>
                                                                    <input
                                                                        name={op}
                                                                        id={op}
                                                                        autoComplete="off"
                                                                        style={isFromFSMIntegration == true ? { border: "2px solid #50af47" } : highlightField ? { border: "2px solid #FBBA00" } : {}}
                                                                        value={value != null ? value : ""}
                                                                        title={inputEditable ? sskpi.inputMessage : ""}
                                                                        inputType={"text"}
                                                                        className={(isNumber && isCalculation) || isDisplayText ? "read-only-total" : standardInvalid ? "invalid" : "read-only"}
                                                                        disabled={inputEditable ? false : true}

                                                                    />
                                                                </Field>
                                                            </OverlayTrigger>
                                                        </>
                                                    )


                                    }
                                </div>
                                <div className="col-1" style={{ paddingTop: 0, paddingLeft: '0.5rem' }}>

                                    {hasDiscussion ?
                                        <a style={{ marginLeft: "0.25rem" }}>
                                            <MdChat color={discussionCollor != "" ? discussionCollor : "#fff"}>
                                            </MdChat>
                                            {IconDiscussionToAnswer(hasDiscussion)}
                                        </a>
                                        :
                                        <></>
                                    }

                                    <a id={op + "_comment"} name={op + "_comment"} onClick={() => collapseByRow(sskpi, op, row)}>
                                        {collapse == (sskpi.subSectionKPIID) && collapseRow == row ?
                                            <FaAngleUp size={20} color={
                                                (comment != "" && comment != null) || (file != "" && file != null) ?
                                                    "green"
                                                    :
                                                    (sskpi.comment == "Mandatory" || sskpi.evidence == "Mandatory") && !disabledField ?
                                                        "red"
                                                        :
                                                        ""
                                            } />
                                            :
                                            <FaAngleDown size={20} color={
                                                (comment != "" && comment != null) || (file != "" && file != null) ?
                                                    "green"
                                                    :
                                                    (sskpi.comment == "Mandatory" || sskpi.evidence == "Mandatory") && !disabledField ?
                                                        "red"
                                                        :
                                                        ""
                                            } />
                                        }
                                    </a>
                                </div>
                            </div>
                        </td >
                    </>
                }
                else {
                    return <>
                        <td key={`subSectionKPI_td`} colSpan={1}>
                        </td>
                    </>
                }
            })
        }

    </>
}

export default RowSimpleGrouped;
