import styled from 'styled-components';

export const Header = styled.div`
    font-size: 14px;
    margin-bottom:0;
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
`;

export const HeaderContainer = styled.div`
    body > div.fade.modal.show > div > div > div.row.w-100.align-items-center.closebtn.modal-header > button {
        margin: 0;
    }

    .auto-height-modal .modal-dialog {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 90vh; /* Adjust this value as needed */
}

.auto-height-modal .modal-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    max-height: 100%;
}

.auto-height-modal .modal-body-flex {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow-y: auto;
}

.auto-height-modal .dashboard-body {
    flex: 1 1 auto;
    overflow-y: auto;
}

.auto-height-modal .card {
    height: 100%;
    overflow-y: auto;
}

.auto-height-modal .card-row {
    flex-shrink: 0;
}
`;

export const DownloadButton = styled.div`
        display: flex;
        justify-content: center;
        background: #234285cc;
        padding: 0.5rem;
        color: #FFF;
        border-radius: 3px;
        cursor: pointer;

        h6 {
            margin: 0;
            padding: 0 0.5rem;
        }
`;

export const ContentTitle = styled.div`
    text-align: left;
    font-size: 14px;
    letter-spacing: 0px;
    color: #112A61;
    padding: 1rem;
    font-weight: 500;
    font-weight: bold;
`;

export const ModalBody = styled.div`    
    padding: 2rem 1rem;
    
    .upload-btn-wrapper {
        position: relative;
    }

    .btn-custom {
        padding: 14px 40px 14px 40px;
        width: 100%;
        height: 11rem;
        background: #F2F5F8;
        border: 2px dashed #ACB4BA;
        border-radius: 8px;
    }

    .upload-btn-wrapper input[type=file] {
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
    }

`;

export const CommentTextArea = styled.textarea`
    background: #F6F8FA 0% 0% no-repeat padding-box;
    border: 1px solid #D3D2E4;
    border-radius: 4px;
    resize: vertical;
    width: 95%;
    margin: 0.5rem 1rem;
    position: relative;

    &.block-comment{
        background-color: transparent !important;
        border: 0 !important;
        resize: none !important;
        font: italic normal normal 14px Montserrat;
        text-align: left;
    }

    &.all-versions {
        height: 5rem;
    }
`;

export const Container = styled.div`
    .dashboard-header{
        font-size: 14px;
        background-color: #102A63;

        .container{
            height: 94px;

            .dashboard-title{
                padding-right: 0;

                h1{
                    font-size: 20px;
                    font-weight: bold;
                    color: #FFFFFF;
                    padding: 36px 0 36px 0;
                }
            }

            .dashboard-filter{
                display: flex;
                padding: 27px 25px 27px 0;

                div:first-child{
                    margin-right: 17px;
                }
                
                select{
                    color: #F6F8FA;
                    background-color: #234285;
                    border: #234285 solid 1px;
                    border-radius: 4px;
                    padding: 11px 0 11px 20px;
                    cursor: pointer;
                    height: 40px;
                    
                    option{
                        background-color: #F6F8FA;
                        color: black;
                        cursor: pointer;
                    }

                    &:focus-visible{
                        outline: none;
                    }
                }


                select:last-child{
                    margin-right: 0;
                }
            }
        }
    }

    .dashboard-body{
        margin-top: 32px;
        animation: fadeIn;
        animation-duration: 1s;

        .show {
            animation: fadeIn;
            animation-duration: 1s;
        }
        
        .year-version-column{
            text-align: center;
        }

        .last-change-column{
            text-align: center !important;
        }

        .cards-header{
            margin-bottom: 14px;
            padding-left: 27px;
            padding-right: 27px;
            display: flex;

            div{
                padding-bottom: 15px;
                text-align: left;
                font-size: 13px;
                letter-spacing: 0px;
                color: #7F7F8A;
                text-shadow: 0px 3px 20px #0000000D;
                padding: 0;
            }
        }
    }

    .dashboard-buttons{
        button{
            text-transform: uppercase;
            text-align: center;
            font-size: 13px;
            width: 262px;
            height: 45px;
            margin-top: 15px;
            margin-left: 0;
            letter-spacing: 0.65px;
            color: #FFFFFF;
            background: #102A63 0% 0% no-repeat padding-box;
            border-radius: 5px;
            opacity: 1;

            svg{
                float: right
            }

            &:hover{
                background-color: #102A63;
            }
        }
    }
`;

export const Card = styled.div`
    background-color: transparent;
    border: 0;

    .card-body{
        display: flex;
        padding: 0;
        background-color: #FFFFFF;
        filter: drop-shadow(2px 2px 5px rgb(0 0 0 / 0.1));
        border-radius: 8px;
        font-weight: bold;
        padding: 10px 27px 10px 27px;
        margin-bottom: 17px;
        cursor: pointer;
        animation: fadeIn;
        animation-duration: 1s;

        &.collapsed{
            color: #102A63;

            svg{
                color: #102A63;
                transform: rotate(180deg);
            }

            .status{
                color: black;
            }
        }

        div{
            position:relative;
            margin: auto;
        }

        .status{
            padding-top: 0;
            text-align: center;
            display: flex;
            justify-content: center;
            text-align: center;
            border-radius: 13px;
            padding: 4px 5px 4px 5px;
            font-size: 12px;

            &.complete{
                background-color: #ABF0B0;
            }

            &.pending{
                background-color: #FFDA7E;
            }

            &.rejected{
                background-color: #FB9D9D;
            }

            &.notStarted{
                background-color: #e6e6e6;
            }
        }

        &:hover{
            background-color: #f6f8fa;
        }
    }

    .card{
        padding-left: 15px;
        background-color: transparent;
        border: 0;
        font-size: 14px;

        .card-row{
            display: flex;

            .card-body{
                padding-left: 11px;
                z-index: 1;
                border-radius: 8px 0 0 8px;
                filter: drop-shadow(2px 2px 5px rgb(0 0 0 / 0.1));
                box-shadow: none;
            }
            
            .card-row-button, .card-row-button-last{
                height: 100%;
                width: 37px;
                background-color: #102A63;
                color: white;
                border: 0;
                padding: 8px 10px 10px 10px;
                animation: fadeIn;
                animation-duration: 1s;
                opacity: 1;
                margin-right: 5px;
                font-weight: bold;

                &:hover{
                    opacity: 0.6;
                }
            }

            .card-row-button-last{
                border-radius: 0 8px 8px 0;
                margin: 0;
            }

            .card-row-buttons-body{
                margin-bottom: 17px;
            }
        }
    }
`;